<template>
    <router-view />
</template>

<script>
import { onBeforeMount } from 'vue';
import useGetUser from '@/modules/user/composables/globals/useGetUser';
import useBilling from "@/modules/user/composables/Dashboard/useBilling";
export default {
  setup() {
    const { getInfoUser } = useGetUser();
    onBeforeMount(async () => {
      await getInfoUser();
    });
    return{
    }
  },
};
</script>