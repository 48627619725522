<template>
  <main class="form-wrapper">
    <router-view />
    <div class="select_lang">
      <span @click="showLang = true" class="selector">{{ $i18n.locale }}</span>
      <div class="options" v-if="showLang">
        <span @click="changeLang(locale)" v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`">{{ locale }}</span>
      </div>
    </div>
    <footer class="font_times_new_roman">
      <p>Copyright 2021 Faber-Castell. {{ $t('copy') }}</p>
    </footer>
  </main>
</template>

<script>
export default {
  data() {
    return {
      showLang: false,
    }
  },
  methods: {
    changeLang(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
      this.showLang = false
    }
  },
}
</script>