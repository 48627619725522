<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import { onBeforeMount } from 'vue';
import useGetUser from '@/modules/user/composables/globals/useGetUser';
export default {
  setup() {
    const { getInfoUser } = useGetUser();
    onBeforeMount(async () => {
      await getInfoUser();
    });
    return{
    }
  },
};
</script>
